<template>
  <div>
    <a-card :bordered="false">
      <div class="queryView">
        <div class="queryView-conditions">
          <a-form layout="inline" :form="form" class="form-label-6">
            <a-form-item label="内部项目编码">
              <a-input
                class="form-control"
                v-decorator="['innerProjectCode']"
                placeholder="请输入内部项目编码"
              ></a-input>
            </a-form-item>
            <a-form-item label="内部项目名">
              <a-input class="form-control" v-decorator="['innerProjectName']" placeholder="请输入内部项目名"></a-input>
            </a-form-item>
            <a-form-item label="楼栋名称">
              <a-input class="form-control" v-decorator="['buildingName']" placeholder="请输入楼栋名称"></a-input>
            </a-form-item>
            <a-form-item label="物业">
              <a-input class="form-control" v-decorator="['properlyName']" placeholder="请输入物业"></a-input>
            </a-form-item>
            <a-form-item label="维修工程师">
              <a-input class="form-control" v-decorator="['engineerName']" placeholder="请输入维修工程师"></a-input>
            </a-form-item>
            <a-form-item label="区域">
              <a-select
                class="form-control"
                v-decorator="['locationCode']"
                placeholder="请选择角色"
                allowClear
                :options="areaList"
              ></a-select>
              <div class="condition-btns">
                <a-button type="primary" @click="doQuery(1)">查询</a-button>
                <a-button type="default" @click="resetAndQuery">重置</a-button>
              </div>
            </a-form-item>
          </a-form>
        </div>
        <div class="queryView-actions">
          <a-button icon="plus-circle" @click="add">新增</a-button>
        </div>
        <div class="queryView-main">
          <a-table
            class="queryView-table"
            rowKey="id"
            bordered
            :loading="loading"
            :columns="tableColumns"
            :dataSource="dataSource"
            :pagination="false"
            size="middle"
          ></a-table>
          <base-pagination
            :totalCount.sync="totalCount"
            :pageNo.sync="pageNo"
            :pageSize.sync="pageSize"
            @change="pageChange"
            size="small"
          />
        </div>
      </div>
    </a-card>
    <!-- 添加/编辑弹框 -->
    <a-modal :title="modal.title" v-model="modal.visible" @ok="handleOk">
      <a-form :form="modal.form" v-bind="modalFormLayout">
        <a-form-item label="内部项目名称">
          <a-auto-complete
            v-decorator="[
              'innerProjectName',
              {
                rules: [{ required: true, message: '请选择' }],
              },
            ]"
            :dataSource="labelOptions(projectList)"
            :filterOption="filterByPY"
            placeholder="请选择内部项目名称"
          ></a-auto-complete>
        </a-form-item>
        <a-form-item label="内部项目编码">
          <a-input
            v-decorator="[
              'innerProjectCode',
              {
                rules: [{ required: true, message: '请选择' }],
              },
            ]"
            :disabled="setAndDisableCode()"
            placeholder="请选择内部项目"
          ></a-input>
        </a-form-item>
        <a-form-item label="楼栋名称">
          <a-input
            v-decorator="[
              'buildingName',
              {
                rules: [{ required: true, message: '请输入楼栋名称' }],
              },
            ]"
            placeholder="请输入楼栋名称"
          ></a-input>
        </a-form-item>

        <a-form-item label="楼栋详细地址">
          <a-input
            v-decorator="[
              'detailAddress',
              {
                rules: [{ required: true, message: '请输入楼栋详细地址' }],
              },
            ]"
            placeholder="请输入楼栋详细地址"
          ></a-input>
        </a-form-item>

        <a-form-item label="物业">
          <a-select
            v-decorator="[
              'properlyMobile',
              {
                rules: [{ required: true, message: '请选择' }],
              },
            ]"
            :options="k01UserList"
            showSearch
            :filterOption="filterByPY"
            placeholder="请选择物业"
            @change="k01Change"
          ></a-select>
        </a-form-item>

        <a-form-item label="维修工程师">
          <a-select
            v-decorator="[
              'engineerMobile',
              {
                rules: [{ required: true, message: '请选择' }],
              },
            ]"
            :options="k02UserList"
            showSearch
            :filterOption="filterByPY"
            placeholder="请选择维修工程师"
            @change="k02Change"
          ></a-select>
        </a-form-item>
        <a-form-item hidden><a-input v-decorator="['properlyName']"></a-input></a-form-item>
        <a-form-item hidden><a-input v-decorator="['engineerName']"></a-input></a-form-item>
        <a-form-item hidden><a-input v-decorator="['buildingCode']"></a-input></a-form-item>

        <a-form-item label="区域">
          <a-select
            v-decorator="[
              'locationCode',
              {
                rules: [{ required: true, message: '请选择' }],
              },
            ]"
            :options="areaList"
            placeholder="请选择区域"
          ></a-select>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { queryViewMixin } from '@/utils/mixin'
export default {
  mixins: [queryViewMixin],

  data() {
    const tableColumns = [
      {
        title: '内部项目编码',
        width: '10%',
        align: 'center',
        dataIndex: 'innerProjectCode',
      },
      {
        title: '内部项目名',
        width: '10%',
        align: 'center',
        dataIndex: 'innerProjectName',
      },
      {
        title: '楼栋名称',
        width: '10%',
        align: 'center',
        dataIndex: 'buildingName',
      },
      {
        title: '楼栋编码',
        width: '10%',
        align: 'center',
        dataIndex: 'buildingCode',
      },
      {
        title: '物业',
        width: '10%',
        align: 'center',
        dataIndex: 'properlyName',
      },
      {
        title: '维修工程师',
        width: '10%',
        align: 'center',
        dataIndex: 'engineerName',
      },
      {
        title: '区域',
        width: '12%',
        align: 'center',
        dataIndex: 'locationName',
      },
      {
        title: '操作',
        width: '10%',
        align: 'center',
        dataIndex: 'detail',
        customRender: (text, row, index) => {
          return (
            <div>
              <span>
                <a
                  class="row-action-btn"
                  onClick={() => {
                    this.edit(row)
                  }}
                >
                  编辑
                </a>

                <a
                  class="row-action-btn"
                  onClick={() => {
                    this.remove(row)
                  }}
                >
                  删除
                </a>
              </span>
            </div>
          )
        },
      },
    ]
    return {
      form: this.$form.createForm(this),
      pageNo: 1,
      pageSize: 10,
      totalCount: 0,
      loading: false,
      tableColumns: tableColumns,
      dataSource: [],

      modal: {
        visible: false,
        form: this.$form.createForm(this),
        title: '',
        data: {},
      },

      areaList: [],
      projectList: [],
      k01UserList: [],
      k02UserList: [],
    }
  },
  computed: {
    typesMap() {
      return {}
    },
  },
  methods: {
    setAndDisableCode() {
      const name = this.modal.form.getFieldValue('innerProjectName')
      const matched = this.projectList.find(item => item.label === name)
      if (matched) {
        if (this.modal.form.getFieldValue('innerProjectCode') !== matched.value) {
          this.modal.form.setFieldsValue({
            innerProjectCode: matched.value,
          })
        }
      }
      return !!matched
    },
    async doQuery(_pageNo = this.pageNo, _pageSize = this.pageSize) {
      this.loading = true
      try {
        const res = await this.$axios.post('/kx/fast-repair/admin/locationinfo/page', {
          ...this.getPageParams(_pageNo, _pageSize),
        })
        const { pageSize, pageNo, totalCount, list } = res.data
        this.totalCount = totalCount
        this.pageSize = pageSize
        this.pageNo = pageNo
        this.dataSource = list
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },
    add() {
      this.resetModal()
      this.modal.visible = true
      this.modal.title = '新增楼栋'
    },
    async edit(row) {
      this.resetModal()
      this.modal.visible = true
      this.modal.title = '编辑楼栋'
      this.modal.data = { ...row }
      this.safeSetFieldsValue(this.modal.form, { ...row })
    },
    async remove(row) {
      await new Promise((resolve, reject) => {
        this.$confirm({
          title: '提示',
          content: '确认删除?',
          onOk: () => {
            resolve()
          },
          onCancel: () => {
            reject(new Error('E_CANCEL'))
          },
        })
      })
      await this.$axios.post('/kx/fast-repair/admin/locationinfo/delete', {
        id: row.id,
      })
      this.$message.success('操作成功!')
      this.doQuery()
    },
    handleOk() {
      this.modal.form.validateFields(async (errors, values) => {
        if (!errors) {
          const params = values
          try {
            const { id } = this.modal.data
            const actionUrl = id
              ? '/kx/fast-repair/admin/locationinfo/update'
              : '/kx/fast-repair/admin/locationinfo/add'
            await this.$axios.post(actionUrl, {
              id,
              ...params,
            })
            this.$message.success('操作成功!')
            this.modal.visible = false
            this.doQuery()
          } catch (e) {
            console.error(e)
          }
        }
      })
    },
    async getAreaList() {
      const res = await this.$axios.post('/kx/fast-repair/admin/common/areaList')
      this.areaList = res.data.map(item => {
        return {
          label: item.value,
          value: item.key,
        }
      })
    },
    async getProjectList() {
      const res = await this.$axios.post('/kx/fast-repair/admin/common/projectList')

      this.projectList = res.data.map(item => {
        return {
          label: item.value,
          value: item.key,
        }
      })
    },
    async getK01UserList() {
      const res = await this.$axios.post('/kx/usercenter/admin/common/getK01UserList')
      this.k01UserList = res.data.map(item => {
        return {
          label: `${item.value} - ${item.key}`,
          value: item.key,
        }
      })
    },
    async getK02UserList() {
      const res = await this.$axios.post('/kx/usercenter/admin/common/getK02UserList')
      this.k02UserList = res.data.map(item => {
        return {
          label: `${item.value} - ${item.key}`,
          value: item.key,
        }
      })
    },
    k01Change(value, vnode) {
      const label = vnode.componentOptions.children[0].elm.data
      const properlyName = label.split(' - ')[0]
      this.modal.form.setFieldsValue({
        properlyName,
      })
    },
    k02Change(value, vnode) {
      const label = vnode.componentOptions.children[0].elm.data
      const engineerName = label.split(' - ')[0]
      this.modal.form.setFieldsValue({
        engineerName,
      })
    },
  },
  async mounted() {
    this.getK01UserList()
    this.getK02UserList()
    this.getAreaList()
    this.getProjectList()
    this.doQuery()
  },
}
</script>

<style lang="less" scoped></style>
